<template>
    <div class="search-input__wrapper" :id="id">
        <b-form-input
            v-model="query"
            type="search"
            class="search-input"
            @input="emitInput"
            :placeholder="placeholder"
            :autocomplete="'off'"
            @click="$emit('click')"
        ></b-form-input>
        <div v-if="icons" class="search-input__icon search-input__icon--left">
            <i :class="{fa: true, 'fa-search': !loading, 'fa-spinner fa-spin': loading}" />
        </div>
        <div v-if="icons" class="search-input__icon search-input__icon--right pointer-action">
            <i class="wit-icon-close" @click="clear" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
        },
        loading: {
            type: Boolean,
        },
        open: {
            type: Boolean,
        },
        icons: {
            type: Boolean,
            default: true,
        },
        placeholder: {
            type: String,
            default: 'Search your resources',
        },
    },
    data() {
        return {
            id: this.$randomId(),
            query: '',
        }
    },
    watch: {
        value() {
            if (this.value !== this.query) {
                this.query = this.value
            }
        },
    },
    created() {
        if (this.value !== this.query) {
            this.query = this.value
        }
    },
    methods: {
        emitInput() {
            this.$emit('input', this.query)
        },

        clear() {
            this.query = ''
            this.emitInput()
            this.$emit('update:open', false)
        },
    },
}
</script>

<style lang="scss">
.search-input__wrapper {
    position: relative;

    .search-input__icon {
        position: absolute;
        top: 0;
        padding: 5px 0;
        height: calc(1.5em + 0.75rem + 2px);

        i {
            color: #9e9e9e;
        }
    }

    .search-input__icon--left {
        left: 8px;
        top: 1px;
    }

    .search-input__icon--right {
        right: 20px;
        top: 13px;
    }

    .search-input {
        background: white;
        padding-left: calc(0.75rem + 15px);
        padding-right: calc(0.75rem + 10px);
    }

    .search-input:focus {
        outline: none;
        box-shadow: none;
        border-color: #e8e8e8;
    }

    .search-input::-webkit-search-cancel-button {
        display: none;
        visibility: hidden;
    }
}
</style>
