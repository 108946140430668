<template>
    <wit-modal
        v-model="showModal"
        title="Image gallery"
        variant="primary"
        disableDBlock
        size="lg"
        customClass="wit-custom-query-storage-gallery"
        @input="emitInput"
    >
        <storage-input v-model="localChosen" placeholder="Search your assets" @input="onInput" no-gallery />
        <search-input v-model="query" placeholder="Search your assets" @input="onQueryChange" />
        <div class="wit-custom-query-storage-gallery__body">
            <div
                v-for="file in files"
                :key="file.url"
                :class="{
                    'wit-custom-query-storage-gallery__tile': true,
                    'wit-custom-query-storage-gallery__tile--chosen': file.url === chosen,
                }"
            >
                <img
                    :src="file.url"
                    :class="{'wit-highlight': file.url === chosen}"
                    @click="() => chooseFile(file.url)"
                />
                <p>
                    {{ file.name }}
                </p>
                <b-button
                    size="sm"
                    variant="primary"
                    class="wit-custom-query-storage-gallery__icon-remove pointer-action"
                    v-b-tooltip.hover
                    title="Remove"
                >
                    <i class="fa fa-close" @click="() => openRemoveModal(file.name)" />
                </b-button>
            </div>
            <div class="wit-custom-query-storage-gallery__loader" v-if="loading">
                <b-spinner class="spinner-running" md label="Loading" variant="primary"></b-spinner>
            </div>
            <div ref="el" class="wit-observer" />
        </div>
        <storage-remove-modal v-model="showRemoveModal" :filename="filename" @remove="remove" />
    </wit-modal>
</template>

<script>
import SearchInput from '@/components/Search/SearchInput.vue'

export default {
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        chosen: {
            type: String,
            default: '',
        },
    },
    components: {
        SearchInput,
        WitModal: () => import('@/components/Modals/WitModal.vue'),
        StorageInput: () => import('./StorageInput.vue'),
        StorageRemoveModal: () => import('./StorageRemoveModal.vue'),
    },
    data() {
        return {
            showModal: false,
            pageToken: null,
            previousPageToken: null,
            files: [],
            loading: false,
            observer: null,
            filename: '',
            showRemoveModal: false,
            query: '',
            timeout: null,
            localChosen: '',
            fetchError: {
                state: null,
                message: '',
            },
        }
    },
    watch: {
        value() {
            this.showModal = this.value
            this.localUrl = this.url

            if (this.showModal) {
                setTimeout(this.refreshObserver, 250)
            }
        },
    },
    created() {
        this.showModal = this.value
        this.localUrl = this.url
    },
    methods: {
        emitInput() {
            this.$emit('input', this.showModal)
        },

        refreshObserver() {
            this.observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry && entry.isIntersecting) {
                        this.fetchFiles()
                    }
                },
                {
                    threshold: 1.0,
                }
            )

            if (this.$refs.el) {
                this.observer.unobserve(this.$refs.el)
                this.observer.observe(this.$refs.el)
            }
        },

        async fetchFiles() {
            if (
                !this.loading &&
                this.pageToken !== 'undefined' &&
                ((this.previousPageToken && this.pageToken && this.previousPageToken !== this.pageToken) ||
                    (!this.pageToken && !this.previousPageToken) ||
                    (!this.previousPageToken && this.pageToken))
            ) {
                this.loading = true

                this.fetchError.state = null
                this.fetchError.message = ''

                try {
                    const res = await this.axios.get(`${process.env.VUE_APP_NODE_API_HOST}/customQuery/storage`, {
                        params: {
                            limit: 9,
                            pageToken: this.pageToken,
                            query: this.query,
                        },
                    })
                    this.files = [...this.files, ...res.data.data.files]

                    this.previousPageToken = this.pageToken
                    this.pageToken = res.data.data.nextPageToken ? res.data.data.nextPageToken : 'undefined'

                    this.refreshObserver()

                    this.loading = false
                } catch (exception) {
                    this.loading = false
                    this.fetchError.state = false
                    this.fetchError.message = 'Could not fetch'
                    console.error(exception)
                }
            }
        },

        openRemoveModal(filename) {
            this.filename = filename
            this.showRemoveModal = true
        },

        async remove() {
            this.$store.commit('loading/PROCESSING', `Removing file...`)

            this.fetchError.state = null
            this.fetchError.message = ''

            try {
                const res = await this.axios.delete(
                    `${process.env.VUE_APP_NODE_API_HOST}/customQuery/storage/${this.filename}`
                )

                const idx = this.files.findIndex(el => el.name === this.filename)
                this.files.splice(idx, 1)

                this.filename = ''
                this.showRemoveModal = false

                this.$store.commit('loading/PROCESSED')
            } catch (exception) {
                this.$store.commit('loading/PROCESSED')

                this.filename = ''
                this.showRemoveModal = false

                this.fetchError.state = false
                this.fetchError.message = 'Could not fetch'

                console.error(exception)
            }
        },

        chooseFile(url) {
            this.$emit('choose', url)

            this.showModal = false
            this.emitInput()
        },

        onQueryChange() {
            if (this.timeout) {
                clearTimeout(this.timeout)
                this.timeout = null
            }

            this.timeout = setTimeout(() => {
                this.pageToken = ''
                this.previousPageToken = ''
                this.files = []

                this.fetchFiles()
            }, 1000)
        },

        onInput(value) {
            this.$emit('choose', value)

            this.showModal = false
            this.emitInput()

            this.localChosen = ''
            this.pageToken = ''
            this.previousPageToken = ''
            this.files = []
        },
    },
}
</script>

<style lang="scss">
.wit-custom-query-storage-gallery {
    .modal-dialog {
        max-height: calc(100% - 500px);
    }

    .modal-dialog-centered.modal-dialog-scrollable {
        .modal-content {
            max-height: calc(100% - 100px);
        }
    }

    .search-input__wrapper {
        margin-bottom: 30px;
    }

    .wit-custom-query-storage-gallery__body {
        display: grid;
        justify-content: center;
        padding: initial;
        grid-template-columns: repeat(auto-fit, minmax(236px, max-content));
        grid-gap: 30px;

        .wit-custom-query-storage-gallery__tile {
            width: 236px;
            height: 124px;
            position: relative;

            p {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            img {
                width: 236px;
                height: 100px;
                object-fit: cover;
                border: 1px solid #e6ecef;
                border-radius: 8px;
                cursor: pointer;
            }

            &:hover {
                img {
                    box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
                }
            }

            .wit-custom-query-storage-gallery__icon-remove {
                position: absolute;
                top: 5px;
                right: 5px;
                padding: 2.5px 7.5px;
                color: #f1c516;
            }
        }

        .wit-custom-query-storage-gallery__loader {
            grid-column: span 3;
            text-align: center;
        }

        .wit-observer {
            grid-column: span 3;
        }
    }
}
</style>
